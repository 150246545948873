import { css } from 'styled-components'

export enum STYLE_FONT_WEIGHT {
  REGULAR = 400,
  BOLD = 700,
  EXTRA_BOLD = 800,
}

export enum STYLE_FONT_SIZE {
  _12 = '12px',
  _14 = '14px',
  _16 = '16px',
  _18 = '18px',
  _22 = '22px',
  _26 = '26px',
  _30 = '30px',
  _40 = '40px',
  _48 = '48px',
  _50 = '50px',
  _55 = '55px',
  _60 = '60px',
  _72 = '72px',
  _100 = '100px',
}

export enum STYLE_LINE_HEIGHT {
  _20 = '20px',
  _28 = '28px',
  _30 = '30px',
  _34 = '34px',
  _36 = '36px',
  _46 = '46px',
  _50 = '50px',
  _52 = '52px',
  _56 = '56px',
  _87 = '87px',
  _92 = '92px',
  _100 = '100px',
  _110 = '110px',
}

export enum STYLE_FONT_FAMILY {
  KAKAO = 'Kakao, Noto Sans KR, -apple-system, BlinkMacSystemFont, sans-serif',
  KAKAO_BIG = 'KakaoBig, Noto Sans KR, -apple-system, BlinkMacSystemFont, nanumbarungothic, nanumgothic, malgun gothic, dotum, sans-serif',
  KAKAO_SMALL = 'KakaoSmall, Noto Sans KR, -apple-system, BlinkMacSystemFont, nanumbarungothic, nanumgothic, malgun gothic, dotum, sans-serif',
  SANS_SERIF = 'Noto Sans KR, -apple-system, BlinkMacSystemFont, nanumbarungothic, nanumgothic, malgun gothic, dotum, sans-serif',
  SERIF = 'serif',
  MONOSPACE = 'monospace',
}

export function toFontSet(
  fontSize?: STYLE_FONT_SIZE,
  fontWeight?: STYLE_FONT_WEIGHT,
  lineHeight?: STYLE_LINE_HEIGHT
) {
  return css`
    font-size: ${fontSize};
    font-weight: ${fontWeight};
    line-height: ${lineHeight};
  `
}

export const FONT_12 = toFontSet(
  STYLE_FONT_SIZE._12,
  STYLE_FONT_WEIGHT.REGULAR,
  STYLE_LINE_HEIGHT._20
)

export const FONT_12_EXTRA_BOLD = toFontSet(
  STYLE_FONT_SIZE._12,
  STYLE_FONT_WEIGHT.EXTRA_BOLD,
  STYLE_LINE_HEIGHT._20
)

export const FONT_14 = toFontSet(
  STYLE_FONT_SIZE._14,
  STYLE_FONT_WEIGHT.REGULAR,
  STYLE_LINE_HEIGHT._20
)

export const FONT_14_BOLD = toFontSet(
  STYLE_FONT_SIZE._14,
  STYLE_FONT_WEIGHT.BOLD,
  STYLE_LINE_HEIGHT._20
)

export const FONT_16 = toFontSet(
  STYLE_FONT_SIZE._16,
  STYLE_FONT_WEIGHT.REGULAR,
  STYLE_LINE_HEIGHT._30
)

export const FONT_16_BOLD = toFontSet(
  STYLE_FONT_SIZE._16,
  STYLE_FONT_WEIGHT.BOLD,
  STYLE_LINE_HEIGHT._30
)

export const FONT_18 = toFontSet(
  STYLE_FONT_SIZE._18,
  STYLE_FONT_WEIGHT.REGULAR,
  STYLE_LINE_HEIGHT._28
)

export const FONT_18_EXTRA_BOLD = toFontSet(
  STYLE_FONT_SIZE._18,
  STYLE_FONT_WEIGHT.EXTRA_BOLD,
  STYLE_LINE_HEIGHT._28
)

export const FONT_22 = toFontSet(
  STYLE_FONT_SIZE._22,
  STYLE_FONT_WEIGHT.REGULAR,
  STYLE_LINE_HEIGHT._34
)

export const FONT_22_EXTRA_BOLD = toFontSet(
  STYLE_FONT_SIZE._22,
  STYLE_FONT_WEIGHT.EXTRA_BOLD,
  STYLE_LINE_HEIGHT._34
)

export const FONT_26_EXTRA_BOLD = toFontSet(
  STYLE_FONT_SIZE._26,
  STYLE_FONT_WEIGHT.EXTRA_BOLD,
  STYLE_LINE_HEIGHT._36
)

export const FONT_30_EXTRA_BOLD = toFontSet(
  STYLE_FONT_SIZE._30,
  STYLE_FONT_WEIGHT.EXTRA_BOLD,
  STYLE_LINE_HEIGHT._46
)

export const FONT_40_EXTRA_BOLD = toFontSet(
  STYLE_FONT_SIZE._40,
  STYLE_FONT_WEIGHT.EXTRA_BOLD,
  STYLE_LINE_HEIGHT._56
)

export const FONT_48_BOLD = toFontSet(
  STYLE_FONT_SIZE._48,
  STYLE_FONT_WEIGHT.BOLD,
  STYLE_LINE_HEIGHT._56
)

export const FONT_50_EXTRA_BOLD = toFontSet(
  STYLE_FONT_SIZE._50,
  STYLE_FONT_WEIGHT.EXTRA_BOLD,
  STYLE_LINE_HEIGHT._50
)

export const FONT_72_EXTRA_BOLD = toFontSet(
  STYLE_FONT_SIZE._72,
  STYLE_FONT_WEIGHT.EXTRA_BOLD,
  STYLE_LINE_HEIGHT._92
)

export const FONT_100_BOLD = toFontSet(
  STYLE_FONT_SIZE._100,
  STYLE_FONT_WEIGHT.BOLD,
  STYLE_LINE_HEIGHT._110
)

export const STYLE_KEEP_WORD = css`
  word-break: keep-all;
  word-wrap: break-word;
  white-space: pre-wrap;
`
